<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <p class="text-center mb-2">
        <img
          :src="require('@/assets/images/logo/cosmo-logo.png')"
          style="height:150px;"
        >
      </p>
      <b-card class="mb-0">

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="login-username"
              :label="$t('username')"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="login-username"
                  v-model="loginForm.username"
                  name="login-username"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('username')"
                  autocomplete="off"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label
                  for="login-password"
                  v-text="$t('password')"
                />
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="loginForm.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    :placeholder="$t('password')"
                    autocomplete="off"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="loginForm.remember_me"
                name="checkbox-1"
              >
                <span v-text="$t('remember_me')" />
              </b-form-checkbox>
            </b-form-group>

            <div
              v-if="isRecaptchaEnable === 'true'"
              class="text-center"
            >
              <div id="g-recaptcha" />
            </div>

            <!-- submit button -->
            <b-button
              id="signInButton"
              class="btn-overlay mt-1"
              variant="primary"
              type="submit"
              block
              :disabled="((isRecaptchaEnable === 'true' && loginForm.captcha === null) || invalid) || loginForm.loading === true"
            >
              <span v-if="loginForm.loading === true">
                <b-spinner small /> <span v-text="$t('loading')" />
              </span>
              <span v-else>
                <span v-text="$t('sign_in')" />
              </span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <p class="text-center mt-2">
        <a
          href="https://cosmogame.net"
          target="_blank"
          v-text="'cosmogame.net'"
        />
      </p>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSpinner, BButton, BForm, BFormInput, BFormGroup, BCard, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BSpinner,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      isRecaptchaEnable: process.env.VUE_APP_ENABLE_RECAPTCHA,
      loginForm: {
        username: typeof this.$route.query.username !== 'undefined' ? this.$route.query.username : '',
        password: '',
        remember_me: false,
        loading: false,
        captcha: null,
      },
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    /* eslint-disable */
    if(this.isRecaptchaEnable === 'true') {
      const self = this
      grecaptcha.render('g-recaptcha', {
        'sitekey': '6LcyzS4lAAAAAMAQ07RPMhfcK5Wko6z5jqI-chaf',
        'callback': function(response){
          self.loginForm.captcha = response
        },
        'expired-callback': function(){
          self.loginForm.captcha = null
        }
      })
    }
    /* eslint-enable */
  },
  methods: {
    async login() {
      this.loginForm.loading = true

      try {
        const response = await useJwt.login(this.loginForm)
        if (response.data.status) {
          useJwt.setToken(response.data.results.accessToken)
          useJwt.setRefreshToken(response.data.results.refreshToken)
          const { userData } = response.data.results
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          await this.$router.replace(getHomeRouteForLoggedInUser(userData ? userData.role : null))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          }, {
            position: 'top-center',
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.results.message,
            },
          }, {
            position: 'top-center',
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.response.data,
          },
        }, {
          position: 'top-center',
        })
      }

      this.loginForm.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
